import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import Vuetify from "vuetify";

Vue.use(Vuex);
Vue.use(Vuetify);

export default new Vuex.Store({
  state: {
    backend_url: 'https://b.elegant-lion-new.uz/',
    permissions: null,
    access_token: Cookies.get("access_token") || "null",
    user: null,
    locale: Cookies.get("locale") || "uz_cyril",
  },
  getters: {
    checkPermission: (state) => (permission) => {
      let localStorage = window.localStorage;
      let permissions = JSON.parse(localStorage.getItem("permissions"));
      if (permissions) return permissions.find((v) => v == permission);
      return false;
    },
    checkRoles: (state) => (role) => {
      let localStorage = window.localStorage;
      let roles = JSON.parse(localStorage.getItem("roles"));
      if (roles) return roles.find((v) => v.name == role);
      return false;
    },
    getUser: (state) => () => {
      let localStorage = window.localStorage;
      let user = JSON.parse(localStorage.getItem("user"));
      if (user) return user;
      return false;
    },
    getRoles: (state) => () => {
      let localStorage = window.localStorage;
      let roles = JSON.parse(localStorage.getItem("roles"));
      if (roles) return roles;
      return false;
    },
    getAccessToken: (state) => () => {
      let localStorage = window.localStorage;
      let access_token = localStorage.getItem("access_token");
      if (access_token) return access_token;
      return false;
    },
  },
  mutations: {
    setUser: (state, arg) => {
      let localStorage = window.localStorage;
      localStorage.setItem("user", JSON.stringify(arg));
    },
    setPermissions: (state, arg) => {
      let localStorage = window.localStorage;
      localStorage.setItem("permissions", JSON.stringify(arg));
    },
    setRole: (state, arg) => {
      let localStorage = window.localStorage;
      localStorage.setItem("roles", JSON.stringify(arg));
    },
    setAccessToken: (state, arg) => {
      let localStorage = window.localStorage;
      localStorage.setItem("access_token", arg);
    },
    setRedirectUrl(state, arg) {
      state.redirect_url = arg;
    },
  },
  actions: {
    setLocale: (state, arg) => {
      Cookies.set("locale", arg);
    },
    setPermissions: (context, arg) => {
      context.commit("setPermissions", arg);
    },
    setUser: (context, arg) => {
      context.commit("setUser", arg);
    },
    setRole: (context, arg) => {
      context.commit("setRole", arg);
    },
    setAccessToken: (context, arg) => {
      context.commit("setAccessToken", arg);
    },
    setRedirectUrl: (context, arg) => {
      context.commit("setRedirectUrl", arg);
    },
  },
  modules: {},
});
