import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '../views/Layout.vue'
import store from '../store'
import Home from '../views/Home'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    component: Layout,
    children: [
    { 
      path: '',
      name: 'home',
      component: resolve => resolve(Home)
    },
    ]
  },
  {
    path: '/admin',
    name: 'Login',
    component: () =>
      import('@/views/Login')
  },
  {
    path: '/roles',
    component: Layout,
    children: [
      {
        path: '/',
        component: () =>
          import('@/views/users/Roles'),
        meta: {
          permission: "roles-show"
        }
      }
    ]
  },
  {
    path: '/permissions',
    component: Layout,
    children: [
      {
        path: '/',
        component: () =>
          import('@/views/users/Permissions'),
        meta: {
          permission: "permissions-show"
        }
      }
    ]
  },
  {
    path: '/users',
    component: Layout,
    children: [
      {
        path: '/',
        component: () =>
          import('@/views/users/Users'),
        meta: {
          permission: "users-show",
        }
      }
    ]
  },
  {
    path: '/diplomas',
    component: Layout,
    children: [
      {
        path: '/',
        component: () =>
          import('@/views/DiplomView')
      }
    ]
  },
  {
    path: '/403',
    component: () =>
      import('@/views/layouts/403'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: () =>
      import('@/views/layouts/404'),
  },


]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.fullPath === "/" && to.name === "home") {
    next();
    return;
  }

  if (to.matched.length === 0) {
    next("/404");
  } else if (
    to.meta.permission &&
    !store.getters.checkPermission(to.meta.permission)
  ) {
    next("/403");
  } else {
    next(); 
  }
});


export default router
