const ru = {
  search: "Поиск",
  update: "Редактировать",
  delete: "Удалить",
  created_at: "Дата создания",
  save: "Сохранить",
  choose: "Выберите файл",
  view: "Просмотр",
  upload: "Загрузить",
  close: "Закрывать",
  swal_sure:"Ты уверен?",
  swal_delete:"Вы не сможете отменить это!",
  cancel: "Отмена",
  loadingText: "Загружается",
  noDataText: "Данные не найдены!",
  new:"Ввод",
  action:"Действие",
  roles:"Роли",
  actions: "Действие",
  clear: "Очистка",
  error_file: "Загруженный файл не вписался в шаблон!",
  errorUniqueVin: "Сменить VIN номер!",
  error_file_title: "Ошибка при загрузке!",
  file_input: "Ввод файла",
  file: "Файл",
  example_file: "Пример файла",
  create_update_operation: "Успешно сохранено!",

  rememberMe: "Запомни меня",
  itemsPerPageText: "",
  itemsPerPageAllText: "Все",
  loginError: "Логин или пароль неверно",
  company_id: "Организация",
  department_id: "Подразделения",
  logout: "Выйти",
  add: "Добавить",
  create: "Создать",
  edit: "Редактировать",
  required: "Поля, отмеченные *, обязательны для заполнения!",
  input_required: "Это поле обязательно для заполнения",
  swal_title: "Вы уверены?",
  swal_text: "это действие не может быть отменено позже",
  // swal_delete: "Да, Удалить!",
  swal_deleted: "Ваш файл был удален!",
  swal_error_title: "Так!",
  swal_error_text: "Что-то пошло не так!",
  name_uz_latin: "Название узбекская латынь",
  name_uz_cyril: "Название узбекская кириллица",
  name_ru: "Название русский",
  name: "Имя",
  description_uz_cyril: "Описание кириллица",
  description_uz_latin: "Описание латинский",
  description_ru: "Описание русс",
  updated_at: "Обновлено в",
  created_by: "Создан:",
  updated_by: "Обновлено:",
  deleted_at: "Удалено в",
  code: "Код",
  filter: "Фильтр",
  workflow: "Документоборот",
  balance: "Баланс",
  created_documents: "Создавать документы",
  approved_documents: "Утвержденный документ",
  files: "Файлы",
  has_employee: "Работник прикреплен",
  start_date: "Дата начала",
  end_date: "Дата завершения",
  main_page: "Главная страница",
  admin: "Админ",
  data: "Справочники",
  incorrect_login: "Введены неверные учетные данные!",

  input: {
    required: "Это поле обязательно для заполнения",
    text: "Ввод текста",
  },
  gender: {
    gender: "Пол",
    male: "Мужчина",
    female: "Женщина",
  },
  message: {
    document_template: "Шаблон документа",
    document_types: "Тип документа",
    signers_group: "Группа подписантов",
    positions: "Должности",
    noMessage: "Нет сообщений",
    noAlerts: "Нет оповещений",
    settings: "Настройки",
    create: "Создать новый документ",
    menu: "Меню",
    home: "Главная страница",
    inbox: "Входящие",
    draft: "Черновик",
    sent: "Посланный",
    logout: "Выйти",
    myCompany: "Моя компания",
    profile: "Профиль",
    tariffScale: "Тарифная сетка",
    coefficient: "Надбавки",
    departmentType: "Тип подразделении",
    personalType: "Тип персонала",
    positionType: "Тип должности",
    expenceType: "Тип расхода",
    requirementType: "Тип требования",
    ranges: "Разряд",
    countries: "Страны",
    regions: "Районы",
    natioanalities: "Национальности",
    districts: "Районы",
    requirement: "Требования к должности",
    documents: "Документы",
  },
  // ss
  realizedvin:{
    sent_data:"Отправленные данные",
    vin: "Идентификационный номер транспортного средства (VIN)",
    sdate:"Дата продажи",
    dealervin_name:"Распределение",
    add_vin: "Добавить идентификационный номер транспортного средства",
    date_required:"Требуется дата",
    vin_required:"Требуется Vin",
    included:"Включена ошибка даты",
    error_written:"Ошибка - написано не на латыни",
    characters:"17 символов",
    update:"Обновить Vin",
  },
  uploadvin:{
    upload_vin:'Загрузить VIN',
    vin:'VIN',
    skd_plant:'Заводской код',
    vin_gm:'Оригинальный  VIN',
    vin_local:'Новое VIN',
    model_code:'Код модели',
    model_year:'Модельный год',
    engine:'Номер двигателя',
    full_option:'Full Option',
    produced_date:'Produce date',
    to_dealer:'Dealer',
    sold_date:'Retail sales date',
  },
  settings:{
    settings:'Настройки',
  },
  filesinfo:{
    // files_info:'Информация о файлах GWM',
    files_info:'Файлах GWM',
    II001:'Файлы II001',
    II002:'Файлы II002',
  },
  dealers:{
    dealers:'Дилеры',
    dealers_centers:'Название организации',
    name:'Распределительные центры  ',
    bac:'Код доступа для бизнеса к GWM (BAC) ',
    country:'Название страны',
    address:'Адрес',
    add_dealers:'Добавление распределительных центров',
    characters:'6 символов',
    bac_required:'Требуется код доступа для бизнеса',
    update:'Дилер по обновлению',
  },
  user: {
    user_list: "Список пользователей",
    username: "Логин",
    email: "Электронная почта",
    name: "Имя",
    role: "Роль",
    password_setting: "Установка па роля",
    add_user: "Добавить пользователя",
    password: "Пароль",
    email_required: "Требуется электронная почта",
    email_valid: "Электронная почта должна быть действительной",
    email_unique: "Такой email существует!",
    required: "Требуемый",
    min_char: "Минимум 8 символов",
    swal_txt: "УДАЛИТЬ ПОЛЬЗОВАТЕЛЯ",



    department_name: "Название подразделение",
    roles: "Роли",
    index: "Пользователи",
    phone: "Телефон",
    dialogHeaderText: "Пользователи",
    department_id: "Подразделения",
    role_id: " Роль",
    position: "Должность",
    newUser: "Добавить новый пользователь",
    updateUser: "Редактировать",
    "role-permission": "Роли -прикрепить разрешение",
    employee: "Сотрудник",
    employee_id: "Сотрудник",
    permission: "Разрешении",
  },
  permission: {
    index: "Разрешении",
    name: "Название разрешения",
    display_name: "Показать имя",
    description: "Описание",
    dialogHeaderText: "Добавить новые разрешения",
    add: "Добавить новые разрешения",
    edit: "Редактировать",
    delete: "Вы уверены, что хотите удалить этот разрешение?",
  },
  role: {
    index: "Роли",
    name: "Наименование роль",
    display_name: "Отображаемое имя",
    description: "Примечание",
    assigned_permissions: "Назначенные разрешения",
    not_assigned_permissions: "Не назначенные разрешения",
    dialogHeaderText: "Назначить разрешения для роль",
    add: "Добавить новую роль",
  },
};

module.exports = ru;