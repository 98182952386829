<template>
  <div>
    <div class="text-center">
      <p class="font-weight-black ma-0 p-0">REPUBLIC OF UZBEKITSAN</p>
      <p class="font-weight-black ma-0 p-0">ELEGANT LION</p>
      <p class="font-weight-black ma-0 p-0">NEW EDUCATION</p>
      <hr>
      <p class="font-weight-black ma-0 p-0">РЕСПУБЛИКА УЗБЕКИСТАН</p>
      <p class="font-weight-black ma-0 p-0">ELEGANT LION NEW EDUCATION</p>
      <p class="font-weight-medium ma-0 p-0">
        Огромное разнообразие курсов только у нас!
      </p>
      <p class="font-weight-medium ma-0 p-0">
        Школа ELEGANT открывает свои двери всем кто готов учиться.
      </p>
    </div>
    <v-row>
      <v-col cols="12" sm="6" class="d-flex justify-center align-center">
        <img
          src="/img/elegantlogo.jpg"
          alt="GWM"
          class="logo-img"
          @click="onLogoClick"
        />
      </v-col>
      <v-col cols="12" sm="6" class="text-center">
        <v-row>
          <v-col cols="12">
            <v-btn
              elevation="2"
              class="equal-width-btn"
              :to="{ path: '/diplomas' }"
              router
            >
              Дипломы и сертификаты
            </v-btn>
          </v-col>
          <v-col cols="12">
            <a
              download="license.jpg"
              href="/files/license.jpg"
              aria-label="Скачать лицензию"
            >
              <v-btn elevation="2" class="equal-width-btn">
                <v-icon left>mdi-cloud-download</v-icon> Скачать лицензию
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12">
            <a
              download="qr.jpg"
              href="/files/qr.jpg"
              aria-label="Скачать QR code для информации"
            >
              <v-btn elevation="2" class="equal-width-btn">
                <v-icon left>mdi-cloud-download</v-icon> Скачать QR code
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12">
            <a
              download="information.jpg"
              href="/files/information.jpg"
              aria-label="Скачать Информацию"
            >
              <v-btn elevation="2" class="equal-width-btn">
                <v-icon left>mdi-cloud-download</v-icon> Скачать Информацию
              </v-btn>
            </a>
          </v-col>
          <v-col cols="12">
            <a
              download="reference.jpg"
              href="/files/reference.jpg"
              aria-label="Скачать справку"
            >
              <v-btn elevation="2" class="equal-width-btn">
                <v-icon left>mdi-cloud-download</v-icon> Скачать справку
              </v-btn>
            </a>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <div class="text-center">
      <p class="font-weight-black ma-0 p-0">Наши курсы</p>
    </div>
    <v-container>
      <v-row>
        <!-- Loop through the projects array -->
        <v-col
          v-for="project in projects"
          :key="project.id"
          cols="12"
          sm="6"
          md="3"
        >
          <v-card class="mx-auto" max-width="240">
            <v-img :src="project.img" height="200px"></v-img>

            <v-card-title>
              <v-tooltip bottom>
                <template #activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on" class="text-truncate">
                    {{ project.title }}
                  </span>
                </template>
                <span>{{ project.title }}</span>
              </v-tooltip>
            </v-card-title>

            <v-card-subtitle>{{ project.category }}</v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-divider></v-divider>
    <v-container>
      <v-carousel hide-delimiters>
        <v-carousel-item
          v-for="(item, i) in items"
          :key="i"
          :src="item.src"
        ></v-carousel-item>
      </v-carousel>
    </v-container>
    <v-divider></v-divider>
    <div class="text-center">
      <p class="font-weight-black ma-0 p-0">Контакт</p>
    </div>
    <v-container>
      <v-row>
        <v-col cols="12" md="6">
          <h4>Адрес: Город Самарканд, Базар крытого рынка</h4>
          <h5>Тел: +998662001515</h5>
        </v-col>
        <v-col cols="12" md="6">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d332.33828053274505!2d66.95854463104148!3d39.65549792230489!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x32a5ede1025532a3!2sElelgant%20school!5e0!3m2!1sru!2s!4v1662781049946!5m2!1sru!2s"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </v-col>
      </v-row>
    </v-container>

    <v-divider></v-divider>
    <v-row justify="center" align="center" class="text-center">
      <v-col>
        <div class="text-lg">
          &copy; 2024.
          <span text class="text-decoration-none">
            Элегант Академия учебный центр
          </span>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      projects: [
        {
          id: 1,
          title: "Персонала по уходу за престарелыми и инвалидами",
          category: "Профессия",
          img: require("@/assets/images/careeing.jpg"),
        },
        {
          id: 2,
          title: "Русский язык",
          category: "Язык",
          img: require("@/assets/images/russian.jpg"),
        },
        {
          id: 3,
          title: "Английский язык",
          category: "Язык",
          img: require("@/assets/images/englishlanguage.jpg"),
        },
        {
          id: 4,
          title: "Повар",
          category: "Профессия",
          img: require("@/assets/images/profession.jpg"),
        },
        {
          id: 5,
          title: "Электрик",
          category: "Профессия",
          img: require("@/assets/images/plumber.jpg"),
        },
        {
          id: 6,
          title: "Художник",
          category: "Профессия",
          img: require("@/assets/images/houseworker.jpeg"),
        },
      ],
      items: [
        { src: "/img/pictures/justphoto.jpg" },
        { src: "/img/pictures/justphoto1.jpg" },
        { src: "/img/pictures/justphoto2.jpg" },
        { src: "/img/pictures/justphoto3.jpg" },
        { src: "/img/pictures/justphoto4.jpg" },
        { src: "/img/pictures/justphoto5.jpg" },
        { src: "/img/pictures/justphoto6.jpg" },
        { src: "/img/pictures/justphoto7.jpg" },
        { src: "/img/pictures/justphoto8.jpg" },
        { src: "/img/pictures/justphoto9.jpg" },
      ],
    };
  },
  methods: {
    onLogoClick() {},
  },
};
</script>

<style scoped>
.equal-width-btn {
  width: 100%;
  max-width: 250px;
}

.logo-img {
  width: 50%;
  max-width: 150px;
  margin-top: 10px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .logo-img {
    width: 70%;
  }
}
.text-truncate {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
}
</style>
