var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{attrs:{"id":"inspire"}},[_c('v-app-bar',{staticClass:"pa-0",attrs:{"id":"edonew-header","app":"","dark":"","height":"97"}},[_c('div',{staticClass:"left-header"},[_c('router-link',{attrs:{"to":"/","custom":""},scopedSlots:_vm._u([{key:"default",fn:function({ navigate, href }){return [_c('span',{staticClass:"text-h5 text-center",staticStyle:{"color":"#163e72","font-weight":"600"},on:{"click":function($event){return _vm.deselectItemAndNavigate(navigate, href)}}},[_c('img',{staticClass:"justify-center",staticStyle:{"width":"30%","margin-top":"10px","cursor":"pointer"},attrs:{"src":"/img/logoelegant.png","alt":"GWM"}})])]}}])})],1),_c('div',{staticClass:"center-header hidden-sm-only hidden-xs-only"},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center"}},[_c('div',{staticClass:"item-container"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"item px-4 mt-4"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(item.visible),expression:"item.visible"}],on:{"click":function($event){return _vm.selectItem(index)}}},[_c('v-hover',{attrs:{"close-delay":"10","open-delay":"10"},on:{"mouseover":function($event){_vm.hoveredItemIndex = index},"mouseleave":function($event){_vm.hoveredItemIndex = -1}},scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-img',{class:[
                      hover || _vm.selectedItemIndex === index
                        ? 'greyscale-image'
                        : 'ungreyscale-image',
                      { 'selected-item': _vm.selectedItemIndex === index },
                    ],staticStyle:{"margin":"auto"},attrs:{"src":hover || _vm.selectedItemIndex === index
                        ? item.src
                        : item.src2}})]}}],null,true)}),_c('p',{staticClass:"mb-0 mt-1 center-header_text",style:({
                  color: _vm.selectedItemIndex === index ? '#000' : '#999',
                  'text-align': 'center',
                })},[_vm._v(" "+_vm._s(item.text)+" ")])],1)])}),0)])]),(_vm.items.some(item => item.visible === true))?_c('div',{staticClass:"right-header d-flex d-md-none justify-center"},[[_c('div',{staticClass:"text-center"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"red--text"},'v-icon',attrs,false),on),[_vm._v("mdi-menu")])]}}],null,false,3827409539)},[_c('v-list',{staticStyle:{"background-color":"antiquewhite"}},[_vm._l((_vm.items),function(item,index){return (item.visible)?_c('v-list-item',{key:index,class:{ 'v-list-item--active': _vm.activeItem === index },on:{"click":function($event){return _vm.redirect(item.url, index)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(item.mdicon))]),_vm._v(_vm._s(item.text)+" ")],1)],1):_vm._e()}),(_vm.user)?_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-login-variant")]),_vm._v("Выход")],1):_vm._e()],2)],1)],1)]],2):_vm._e(),(_vm.user)?_c('div',{staticClass:"right-header hidden-sm-only hidden-xs-only"},[_c('v-btn',{attrs:{"color":"black","outlined":"","small":""},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Выход"),_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-logout")])],1)],1):_vm._e()]),_c('v-main',{staticStyle:{"padding":"110px 0px 0px 10px","background-color":"#fecd07"}},[_c('router-view')],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }