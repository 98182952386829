<template>
  <v-app id="inspire">
    <v-app-bar id="edonew-header" app dark height="97" class="pa-0">
      <div class="left-header">
        <router-link to="/" v-slot="{ navigate, href }" custom>
          <span
            class="text-h5 text-center"
            style="color: #163e72; font-weight: 600"
            @click="deselectItemAndNavigate(navigate, href)"
          >
            <img
              src="/img/logoelegant.png"
              alt="GWM"
              class="justify-center"
              style="width: 30%; margin-top: 10px; cursor: pointer"
            />
          </span>
        </router-link>
      </div>
      <div class="center-header hidden-sm-only hidden-xs-only">
        <div
          style="display: flex; justify-content: center; align-items: center"
        >
          <!-- Navbar items boshlandi -->
          <div class="item-container">
            <div
              v-for="(item, index) in items"
              :key="index"
              class="item px-4 mt-4"
            >
              <span @click="selectItem(index)" v-show="item.visible">
                <v-hover
                  close-delay="10"
                  open-delay="10"
                  @mouseover="hoveredItemIndex = index"
                  @mouseleave="hoveredItemIndex = -1"
                >
                  <template v-slot="{ hover }">
                    <v-img
                      :src="
                        hover || selectedItemIndex === index
                          ? item.src
                          : item.src2
                      "
                      :class="[
                        hover || selectedItemIndex === index
                          ? 'greyscale-image'
                          : 'ungreyscale-image',
                        { 'selected-item': selectedItemIndex === index },
                      ]"
                      style="margin: auto"
                    />
                  </template>
                </v-hover>
                <p
                  class="mb-0 mt-1 center-header_text"
                  :style="{
                    color: selectedItemIndex === index ? '#000' : '#999',
                    'text-align': 'center',
                  }"
                >
                  {{ item.text }}
                </p>
              </span>
            </div>
          </div>
          <!-- Navbar items tugadi -->
        </div>
      </div>
      <div class="right-header d-flex d-md-none justify-center" v-if="items.some(item => item.visible === true)">
        <template>
          <div class="text-center">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon class="red--text" v-bind="attrs" v-on="on"
                  >mdi-menu</v-icon
                >
              </template>
              <v-list style="background-color: antiquewhite;">
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                  v-if="item.visible"
                  @click="redirect(item.url, index)"
                  :class="{ 'v-list-item--active': activeItem === index }"
                >
                  <v-list-item-title>
                    <v-icon class="mr-2">{{ item.mdicon }}</v-icon
                    >{{ item.text }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout()" v-if="user"><v-icon class="mr-2">mdi-login-variant</v-icon>Выход</v-list-item>
              </v-list>
            </v-menu>
          </div>
        </template>
      </div>
      <div class="right-header hidden-sm-only hidden-xs-only" v-if="user">
        <v-btn color="black" outlined small @click="logout()">
          Выход<v-icon class="mr-2">mdi-logout</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
    <v-main style="padding: 110px 0px 0px 10px; background-color: #fecd07">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
const axios = require("axios").default;
export default {
  data() {
    return {
      loading: false,
      drawer: true,
      items: [],
      hoveredItemIndex: -1,
      selectedItemIndex: -1,
      activeItem: null,
    };
  },
  computed: {
    screenHeight() {
      return window.innerHeight - 170;
    },
    user() {
      return this.$store.getters.getUser();
    },
    screenHeight() {
      return window.innerHeight;
    },
    screenWidth() {
      return window.innerWidth;
    },
  },
  methods: {
    redirect(url, index) {
      this.activeItem = index;
      this.$router.push(url);
    },
    selectItem(index) {
      const currentRoute = this.$route.path;

      if (this.items[index].url === "/users" && currentRoute !== "/users") {
        this.$router.push("/users");
        this.selectedItemIndex = index;
      } else if (
        this.items[index].url === "/permissions" &&
        currentRoute !== "/permissions"
      ) {
        this.$router.push("/permissions");
        this.selectedItemIndex = index;
      } else if (
        this.items[index].url === "/roles" &&
        currentRoute !== "/roles"
      ) {
        this.$router.push("/roles");
        this.selectedItemIndex = index;
      } else {
        this.selectedItemIndex = index;
      }
    },
    deselectItemAndNavigate(navigate, href) {
      this.selectedItemIndex = -1;
      navigate(href);
    },
    logout() {
      this.storage.clear();
      this.$router.push("/");
      window.location.reload();
    },
  },
  mounted() {
    this.items = [
      {
        src: "img/svg/math-book-svgrepo-com.svg",
        src2: "img/svg/math-book-svgrepo-com.svg",
        text: "Курсы",
        url: "/courses",
        visible: false,
        mdicon: "mdi-book-open-page-variant",
      },
      {
        src: "img/svg/agenda-phone-number-svgrepo-com.svg",
        src2: "img/svg/agenda-phone-number-svgrepo-com.svg",
        text: "Контакт",
        url: "/contact",
        visible: false,
        mdicon: "mdi-flag-variant",
      },
      {
        src: "img/svg/users-svgrepo-com.svg",
        src2: "img/svg/users-svgrepo-com.svg",
        text: "Пользователи",
        url: "/users",
        visible: this.$store.getters.checkRoles("superadmin"),
        mdicon: "mdi-account-multiple-outline",
      },
      {
        src: "img/svg/key-management-service-svgrepo-com.svg",
        src2: "img/svg/key-management-service-svgrepo-com.svg",
        text: "Разрешения",
        url: "/permissions",
        visible: this.$store.getters.checkRoles("superadmin"),
        mdicon: "mdi-account-star",
      },
      {
        src: "img/svg/identity-aware-proxy-svgrepo-com.svg",
        src2: "img/svg/identity-aware-proxy-svgrepo-com.svg",
        text: "Разрешения",
        url: "/roles",
        visible: this.$store.getters.checkRoles("superadmin"),
        mdicon: "mdi-inbox-arrow-down",
      },
    ];
  },
};
</script>
<style scoped>
#edonew-header {
  height: 101px !important;
  left: 0 !important;
  background: #fecd07;
  box-shadow: none !important;
  border-bottom: 4px solid rgb(255, 0, 0);
}
#edonew-header {
  transition: border-color 0.3s;
}

#edonew-header:hover {
  border-color: #6e1ccd;
}

#edonew-header:hover .border-on-hover {
  border-color: #6e1ccd;
}

.left-header {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  width: 200px;
}
.center-header_text {
  line-height: 1.4em;
  overflow: hidden;
  text-decoration: inherit;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  font-family: Roboto, Trebuchet MS, Helvetica, sans-serif;
  font-size: 14px;
  color: #474747 !important;
}
.right-header {
  position: absolute;
  width: 188px;
  float: right;
  right: 0;
}

#edonew-sidebar {
  top: 101px !important;
}

.v-toolbar__content {
  border: 1px solid red !important;
}

.greyscale-image {
  cursor: pointer;
  width: 40px;
  height: 40px;
  transform: scale(1.3);
  transition: transform 0.3s ease;
}

.ungreyscale-image {
  width: 40px;
  height: 40px;
  filter: grayscale(100%);
}

.v-toolbar__content {
  padding: 0 !important;
}
.item-container {
  display: flex;
  justify-content: space-between;
}

.item {
  flex: 1;
  max-width: 200px;
  margin: 0 10px;
}
</style>
